.footer {
  background: #764944;
}

.footer-content {
  color: #F49F9F;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

/* .footer-border {
  border-bottom: 1px solid #024DDF;
} */

.footer-box {
  padding-top: 64px;
}

.footer-img {
  /* width: 400px;
  height: 65.864px; */
  width: 142px;
  height: 32px;
}

.footer-link h1 {
  color: #E45D09;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.footer-link a {
  /* width: 80px; */
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: block;
  text-decoration: none;
  padding-bottom: 8px;
}

.artist-follow-icon {
  margin-top: 10px !important;
}

.footerarr {
  color: #EF7171;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 20px 0px;
}

.social-media-icons {
  display: flex;
  gap: 20px;
  background-color: #764944 !important;
  color: #F49F9F;
}

.ratings-img {
  width: 170px;
}

@media (max-width: 768px) {
  .footer-img {
    width: 250px;
    height: 50.864px;
  }

  .footer-box {
    padding-top: 40px;
  }

  /* .artist-follow-icon {
    display: flex;
    flex-direction: column;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .footerarr {
    margin-top: 5px !important;
    margin-bottom: 10px !important;
  } */

  /* .ratings {
    text-align: center;
  } */
}