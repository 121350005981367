#header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  background-color: #f9f4f0;
  /* border-bottom: 1px solid #f9f4f0; */
}

/* Select Country */
.select-country {
  color: #667085;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d0d5dd;
  border-radius: 16px;
  padding: 0;
  margin: 0;
}

.w-15 {
  width: 214px;
  font-size: 14px;
}

[type="search"]::-webkit-search-cancel-button {
  height: 10px;
  /* color: #667085; */
  background-color: #667085;
  cursor: pointer;
  width: 10px;
}

.rightBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.header-hr {
  border: 1px solid #e4e7ec !important;
  margin: 0px !important;
  opacity: 1;
}

.profile-btn,
.terms-btn {
  display: flex;
  justify-content: center;
  gap: 15px;
  border: 1px solid #D0D5DD;
  border-radius: 16px;
  align-items: center;
  background-color: #fff;
  color: #344054;
  padding: 7px 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
}

.header-event-location {
  color: #667085;
  margin-right: 8px;
}

/* Drop-down Functionality */
.drop-down-menu {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
  background-color: #f9f4f0;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

.profile-letter {
  color: #667085;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  background: #F2F4F7;
  box-sizing: border-box;
  width: 26px;
  height: 26px;
}

/* Show dropdown menu on hover */
.drop-down-menu:hover .dropdown-menu {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  background-color: #f9f4f0;
  margin-top: 5px;
}

.dropdown-item {
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
  margin: 3px;
  justify-content: start !important;
}

.hover-filed {
  width: 100%;
}

.hover-filed:hover {
  background-color: #f9fafb;
  border: none;
  border-radius: 5px;
}

.dropdown-item {
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
  margin: 3px;
  justify-content: start !important;
}

.dropdown-item:hover {
  background-color: #f9fafb !important;
  color: #000 !important;
}

.drop-down-menu:active {
  background-color: #f9fafb !important;
  color: #000 !important;
}

.profile-icon,
.menu-icon {
  display: flex;
  align-items: center;
}

.name {
  font-size: 16px;
  font-weight: 500;
}


.location-icon {
  color: #667085;
}

.selected-text {
  color: #101828;
}

.search-box {
  background-color: #fff;
  border-bottom: 1px solid #c6c6c6;
  padding: 10px 0px;
}

.form-control {
  color: #667085;
}

.search-control {
  color: #667085 !important;
  font-size: 16px !important;
}

input:focus {
  border: none;
  outline: none;
}

.header-select {
  background-color: #ffffff;
  border: 1px solid #D0D5DD;
  border-radius: 16px;
  padding: 7px 6px;
  font-size: 14px;
  font-weight: 500;
  color: #101828;
}

.header-select-country {
  background: none;
  color: #BC1B06;
  border: none;
  margin-top: 1px;
  margin-left: 1px;
  font-size: 16px;
}

.header-select-country:focus-visible {
  outline: none;
}

.location {
  color: #024ddf;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 10px;
}

.event-list-btn {
  /* border: 1px solid #f9f9f9; */
  border: none;
  border-radius: 16px;
  /* width: 200px; */
  background-color: #f98a45 !important;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  gap: 3px;
  color: #FFFFFF !important;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 20px !important;
  margin-left: 20px;
  text-decoration: none;
}

.event-list-btn:focus {
  background-color: #f98a45 !important;
  color: #FFFFFF !important;
}

.search-inp-box {
  display: flex;
  width: 300px !important;
  height: 100% !important;
  min-height: 100%;
  padding: 2px 16px;
  align-items: center;
  border: 1px solid #D0D5DD;
  border-radius: 16px;
  background: #ffffff;
}

.search-inp-box input {
  color: #636387;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 24px; */
  /* width: 285px; */
  outline: none;
  background-color: #ffffff;
  border: none;
}

.search-inp-box input:focus {
  outline: none;
  border: none;
}

.search-inp-box:focus-within {
  border: 1px solid #dc1919;
}

#header.header-scrolled,
#header.header-inner-pages {
  background: #f9f4f0;
  box-shadow: 0px 2px 20px rgba(72, 86, 100, 0.1);
}

.forsticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: 1s ease-in-out;
}

/* #header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
} */

#header .logo a {
  background: none;
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  width: 160px;
  object-fit: contain;
}

.navbar {
  width: 100%;
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
  /* padding: 0px 12px; */
}

.navbar li img {
  width: 41px;
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 0 10px 55px; */
  font-size: 15px;
  font-weight: 500;
  color: rgba(58, 71, 83);
  white-space: nowrap;
  transition: 0.3s;
  background-color: transparent;
  outline: none;
}

.mobile-outline-none:focus-visible {
  outline: none;
}

.icon-link {
  color: #024ddf;
  cursor: pointer;
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.5);
  transition: 0.3s;
  z-index: 999;
}

.mobile-nav-toggle {
  color: #344054;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.list-style-none {
  list-style-type: none;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: flex !important;
  flex-direction: column;
  gap: 5px;
  list-style: none;
  padding: 10px 0;
  height: 100%;
  width: calc(100% - 100px) !important;
  background-color: #f9f4f0;
  overflow-y: auto;
  transition: 0.3s;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.navbar-mobile ul li {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar-mobile ul li .mobile-div {
  display: flex !important;
  flex-direction: column;
  gap: 15px !important;
  padding: 7px;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  /* padding: 10px 20px; */
  font-size: 15px;
  color: white;
}

.login-cta {
  border-radius: 10px;
  border: 1px solid #024ddf;
  padding: 10px 20px !important;
  color: #024ddf !important;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
}

.logout-box {
  position: absolute;
  top: 45px;
  background-color: #fff;
  color: black;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 14px;
  border-radius: 5px;
  width: max-content;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.logout-box:hover {
  color: #024ddf;
  border: 1px solid #024ddf;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.img-cont {
  position: relative;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.img-fluid {
  width: 300px;
}

.img-dropdown {
  position: absolute;
  top: 60px;
  right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.img-dropdown ul {
  list-style: none;
  margin: 0;
  display: block;
}

.img-dropdown ul li {
  padding: 10px 40px;
  cursor: pointer;
}

.logout-model h3 {
  color: #101828;
  font-size: 30px;
  line-height: 38px;
  font-weight: 600;
  text-align: center;
}

.logout-model p {
  color: #475467;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0px;
}

.logout-cross {
  color: #98A2B3;
}

.logout-model img {
  height: 370px;
}

.stay-btn {
  padding: 10px 24px;
  border: 1px solid #D0D5DD;
  border-radius: 16px;
  background-color: #FFFFFF;
  color: #344054;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-right: 12px;
}

.later-btn {
  background-color: #F98A45 !important;
  color: #FFFFFF !important;
  margin-left: 10px;
}

.nav-active {
  display: none !important;
}

@media (max-width: 1400px) {

  .header-select-country {
    font-size: 13px;
  }

  .header-title {
    font-size: 15px;
    width: 60%;
  }

  .custom-w-full {
    width: 100%;
  }

  .search-inp-box {
    width: 250px;
  }

  .hover-filed a {
    background-color: #f9fafb;
    border: none;
    color: #344054;
    border-radius: 5px;
  }

  .dropdown-item {
    background-color: none !important;
    color: #344054 !important;
  }

  .dropdown-item:hover {
    background-color: #f9faf9 !important;
  }

  .dropdown-item:active {
    background-color: #f9faf9 !important;
  }
}

@media (max-width: 1200px) {
  .change-justify-between {
    justify-content: start !important;
  }

  .change-justify {
    justify-content: center !important;
  }

  .always-visible {
    display: flex;
    flex-direction: column;
  }

  .nav-active {
    display: flex !important;
  }

  .navbar ul {
    display: none !important;
  }

  .header-select {
    font-size: 12px;
    order: 1 !important;
  }

  .hide-logo-city {
    display: none !important;
  }

  .d-display-email {
    display: block;
  }

  .list-second {
    order: 2 !important;
    width: 100% !important;
  }

  .profile-btn {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: start;
    order: 0 !important;
    padding: 7px 10px;
    width: auto;
    cursor: default;
  }

  .mobile-search-btn {
    display: flex !important;
  }

  .header-hr {
    display: flex !important;
  }

  .dropdown-menu {
    border: none !important;
  }

  .d-email-flex {
    display: flex !important;
  }

  .name {
    display: flex !important;
    font-weight: 600 !important;
  }

  .small-screens {
    display: flex !important;
  }

  .search-inp-box {
    width: 100% !important;
  }

  .dropdown-menu.dropdown-always-visible {
    position: static !important;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important;
    background-color: transparent;
    margin-top: 0;
    padding: 0;
    box-shadow: none;
    width: 100% !important;
  }

  .hover-filed a {
    background-color: #f9fafb;
    border: none;
    color: #344054 !important;
    border-radius: 5px;
  }

  .dropdown-item {
    background-color: none !important;
    color: #344054 !important;
  }

  .dropdown-item:hover {
    background-color: #f9faf9 !important;
  }

  .dropdown-item:active {
    background-color: #f9faf9 !important;
  }

  .rightBtn {
    display: flex !important;
    flex-direction: column;
    gap: 8px !important;
  }

  .user-nav-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .mobile-nav-toggle {
    display: block;
  }

  .event-list-btn {
    margin-left: 0px;
    font-size: 14px;
    padding: 8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 175px;
    background-color: #f98a45 !important;
    color: #FFFFFF !important;
  }

  .event-list-btn:focus {
    background-color: #f98a45 !important;
    color: #FFFFFF !important;
  }
}

@media (max-width: 768px) {

  .navbar-mobile a,
  .navbar-mobile a:focus {
    /* padding: 10px 20px 5px 20px; */
    font-size: 15px;
    color: white;
  }

  #header .logo img {
    max-height: 45px;
  }

  #header {
    padding: 10px 0px;
  }

  .listing-box {
    justify-content: space-between;
  }

  .search-inp-box {
    width: 100% !important;
    padding: 5px;
    margin-top: 14px;
  }

  .search-inp-box input {
    width: 250px;
  }

  .hover-filed a {
    background-color: #f9fafb;
    border: none;
    color: #344054 !important;
    border-radius: 5px;
  }

  .dropdown-item {
    background-color: none !important;
    color: #344054 !important;
  }

  .dropdown-item:hover {
    background-color: #f9faf9 !important;
  }

  .dropdown-item:active {
    background-color: #f9faf9 !important;
  }

  .nav-data {
    height: 45px;
    width: 240px;
  }

  .user-nav-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .login-cta {
    border-radius: 10px;
    border: 1px solid #024ddf;
    padding: 7px 15px !important;
    color: #024ddf !important;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }

  .logout-cta {
    border-radius: 10px;
    padding: 7px 14px !important;
    color: #024ddf !important;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    border: 1px solid #024ddf;
  }

  .logout-model h3 {
    font-size: 20px;
    line-height: 40px;
  }
}

@media (max-width: 480px) {
  .navbar-mobile ul {
    width: calc(100% - 60px) !important;
  }
}

@media (max-width: 313px) {
  .navbar-mobile ul {
    width: 100% !important;
  }
}

@media screen and (max-width: 2000px) {
  .user-nav-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .hover-filed a {
    background-color: transparent !important;
    border: none;
    color: #344054 !important;
    border-radius: 5px;
  }

  .dropdown-item {
    background-color: none !important;
    color: #344054 !important;
  }

  .dropdown-item:hover {
    background-color: transparent !important;
  }

  .dropdown-item:active {
    background-color: #f9faf9 !important;
  }
}