.custom-input-number::-webkit-inner-spin-button,
.custom-input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.back-btn {
  color: #121212;
  font-size: 20px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.icon-box {
  padding: 5px;
  border-radius: 10px;
  background: #e9ecf0;
  width: fit-content;
}

.banner-image>div>div>div>div {
  height: 100%;
}

.h-80 {
  height: 500px;
}

/* .slick-img {
  height: 30%;
  border-radius: 20px;
  border: none;
  align-items: center;
} */
/* .slick-img img{
  height: 90%;
  padding: 0px 5px;
} */

.slick-slide {
  height: auto !important;
  margin: 0px 8px 0px 8px;
}

.slick-slide .banner-img {
  height: 100% !important;
  border: 0.5px #12121233;
  border-radius: 6.66px !important;
}

.slick-track {
  display: flex !important;
  /* justify-content: center; */
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;
}

.slide-main-box .slick-prev {
  left: -65px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  z-index: 5;
  fill: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.slide-main-box .slick-prev:before {
  content: "";
  background-image: url("../../../Assets/left.png");
  background-size: cover;
  height: 40px;
  width: 30px;
  display: inline-block;
}

.slide-main-box .slick-next {
  right: -65px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  z-index: 5;
  background-color: transparent;
  fill: rgba(255, 255, 255, 0.8);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.slide-main-box .slick-next:before {
  content: "";
  background-image: url("../../../Assets/right.png");
  background-size: cover;
  height: 40px;
  width: 30px;
  display: inline-block;
}

.slide-main-box .slick-prev:hover,
.slide-main-box .slick-next:hover {
  background-color: transparent;
  scale: 1.1;
}

.slide-main-box .slick-dots {
  bottom: 10px;
}

.slick-dots {
  margin-bottom: 7px !important;
}

.slide-main-box .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 7px;
  transition: 0.25s ease-out;
  opacity: 0.75;
  background: #e9ecf0;
  border-radius: 50%;
  border: 1px solid #282828;
}

.slide-main-box .slick-dots .slick-active {
  /* background-color: #024ddf; */
  background-color: #F98A45;
  border: none;
}

.slide-main-box .slick-dots li button:before {
  content: "" !important;
}

.special-instruction {
  color: #2E0505;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.title {
  color: #2E0505;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.25px;
  margin-bottom: 0px;
}

.filling-tab {
  display: inline-flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  background: linear-gradient(0deg, #ffd869 0%, #ffd869 100%), #ffd869;
  margin-left: 40px;
  min-width: 20%;
}

.filling-tab h4 {
  color: #121212;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.25px;
  margin-bottom: 0px;
}

.info {
  color: #344054;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 0px;
}

.bidding-offer h4 {
  color: #23ba20;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin: 0px 5px;
}

.bidding-offer span {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}

.info-data-desc {
  /* margin: 32px 0px; */
  font-size: 16px;
  color: #344054;
  font-weight: 500;
  line-height: 24px;
}

.info-data h5 {
  margin-top: 30px;
  color: #024ddf;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}

.info-data p span {
  font-weight: 800 !important;
}

.info-data p {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-align: justify !important;
}

.org-head-user {
  color: #2E0505;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.user-org-box img {
  height: 60px;
  width: 60px;
  border-radius: 12px;
  object-fit: contain;
}

.org-name {
  color: #344054;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.org-description {
  color: #475467;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.mt-32 {
  margin-top: 32px;
}

.org-details {
  background-color: #FFFFFF;
  /* width: 900px; */
  border-radius: 16px;
  padding: 16px;
}

.sponsors {
  color: #101828;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}

.sponsor-shadow {
  box-shadow: 0px 8px 30px -2px #F98A4540;
}

.sponsor-badge {
  color: #F9FAFB;
  border: 1px solid #E4E7EC;
  border-radius: 16px;
  background-color: #764944;
  margin: 5px;
  padding: 3px 10px;
  text-align: center;
  width: fit-content;
}

.venue {
  margin-top: 30px;
  color: #024ddf;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}

.contact {
  margin-top: 10px;
  color: #024ddf;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}

.ticket-availability {
  color: #121212;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin: 0px 5px;
}

.direction-btn {
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1.7px solid rgba(2, 77, 223, 0.25);
  background: #fff;
  color: #024ddf;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.buynow-btn {
  padding: 5px 100px;
  border-radius: 16px;
  background: #121212;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.artist-head {
  color: #121212;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin: 25px 0px 25px 55px;
}

.artist-detail {
  width: 100%;
  padding: 16px 26px 12px 26px;
  gap: 14px;
  border-radius: 10px;
  border: 1.7px solid rgba(2, 77, 223, 0.25);
  background: #fff;
}

.artist-img img {
  width: 120px;
  height: 120px;
  border-radius: 60px;
}

.artist-info h6 {
  color: #121212;
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 0px;
}

.artist-info h4 {
  color: #024ddf;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
}

.artist-desc p {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}

.artist-desc h6 {
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.25px;
}

.artist-desc h4 {
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.25px;
}

.artistsimg img {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin: 5px 0px 5px 0px;
}

.artistsimg p {
  color: #121212;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.artist-follow-head {
  color: #121212;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.25px;
}

.artist-follow-icon {
  gap: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.dupage-banner-event {
  width: 100%;
  height: 60%;
}

.select-ticket {
  border: 1px solid rgb(107, 106, 106);
  border-radius: 10px;
  padding: 15px;
  width: 98%
}

.order-summary-div {
  border-radius: 10px;
  padding: 15px;
  width: 98%
}

.order-summary-div h5 {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 200% */
  letter-spacing: 0.25px;
}

.select-ticket h5 {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 200% */
  letter-spacing: 0.25px;
}

.pass-type {
  color: #024DDF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.card-title {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.cardTitleDes {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #344054;
}

.price-quantity {
  min-width: 47%;
}

.winterGalaSpimages {
  border-radius: 10px;
  max-width: 400px;
  max-height: 150px;
  width: auto;
  height: auto;
  object-fit: contain;
}

.exeImages {
  border-radius: 10px;
  max-width: 400px;
  max-height: 250px;
  width: auto;
  height: auto
}

.buy-now-fixed {
  /* width: 200px;
  height: 75px; */
  background-color: #F98A45 !important;
  border: 2px solid #FFFFFF1F;
  border-radius: 16px !important;
  padding: 12px 18px;
  /* box-sizing: 0px 1px 2px 0px #1018280D; */
  gap: 6px;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px;
  color: white !important;
}

#fixedBuyNow {
  display: none;
}

.ticket-wrap {
  border: 0.5px solid rgb(13, 35, 158);
  border-radius: 10px;
  padding: 10px;
  width: 100%
}

.mobile {
  display: run-in;
}

.ticket-price-mobile {
  color: #E45D09;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
  border-radius: 16px;
}

/* .gap-5px {
  gap: 5px;
} */

.mobile-bottom-price {
  font-size: 14px;
  color: #E45D09;
  font-weight: 500;
  line-height: 20px;
}

.bgContainer {
  background-color: #F2F2F2;
}

.sponsorImages {
  width: 120px;
  height: 120px;
}

.bloomImages {
  width: 430px;
  height: 500px;
  border-radius: 10px;
  border: 1px solid black;
}

.ev-banner-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 6.66px;
}

.banner-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(10px);
  z-index: 1;
  border-radius: 10px;
}

.banner-img {
  position: relative;
  z-index: 2;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  object-position: center;
  display: block;
  margin: auto;
  border-radius: 10px;
}

.book-now {
  display: flex;
  gap: 7px;
  background-color: #F98A45;
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 16px;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.book-now:hover {
  /* background-color: #850f0f; */
  transition-duration: .5s ease-in-out;
}

.sale-status-1 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054 !important;
  background-color: #F9FAFB;
  border: 1px solid #E4E7EC;
  border-radius: 8px;
  padding: 2px 6px;
}

.sale-status-1-total {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.sale-status-1-btn {
  background: #F2F4F7 !important;
  border: 1px solid #E4E7EC !important;
  box-shadow: 0px 1px 2px 0px #1018280D !important;
  color: #98A2B3 !important;
}

.sale-status-2 {
  color: #101828 !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-align: center !important;
}

.sale-start-title {
  color: #101828 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
}

.timing-desc {
  color: #344054;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.sale-status-3 {
  color: #E45D09;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.sale-timer {
  display: flex;
  gap: 8px;
}

.number {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #E45D09;
}

.desc {
  font-size: 14px;
  color: #6c757d;
  margin-top: 2px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .banner-wrapper {
    width: 800px;
    height: 450px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .banner-wrapper {
    width: 390px;
    height: 220px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1200px) {
  .mobile {
    display: none
  }
}

@media only screen and (min-width: 1399px) and (max-width: 1450px) {

  .slide-main-box .slick-prev {
    left: -45px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    z-index: 5;
    fill: rgba(255, 255, 255, 0.8);
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .slide-main-box .slick-next {
    right: -45px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    z-index: 5;
    background-color: white;
    fill: rgba(255, 255, 255, 0.8);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}

@media (max-width: 768px) {

  .bloomImages {
    width: 330px;
    height: 450px;
    border-radius: 10px;
    border: 1px solid black;
  }

  .winterGalaSpimages {
    border-radius: 10px;
    max-width: 400px;
    max-height: 100px;
    width: auto;
    height: auto;
    object-fit: contain;
  }

  .dupage-banner-container>div>div>div>div img {
    height: 145px !important;
  }

  .ev-banner-container>div>div>div>div img {
    height: 150px;
  }

  .slide-main-box .slick-dots {
    top: 170px;
  }

  .buynow-btn {
    width: 100%;
    margin-top: 15px;
  }

  .artist-head {
    margin: 25px 0px 25px 0px;
  }

  .artist-detail {
    width: 100%;
  }

  .dupage-banner-event {
    width: 100%;
    height: 100%;
  }

  .filling-tab {
    min-width: 40%;
  }

  .select-ticket {
    width: 100%;
  }

  .mobile {
    display: none
  }

  .bgContainer {
    background-color: #b9b6b6;
  }

  .divBg {
    background-color: #fff;
  }

  .sponsorImages {
    width: 90px;
    height: 90px;
  }

  .ticket-d-none {
    display: flex !important;
  }

}

@media (max-width: 575px) {

  .ordersm-0 {
    order: 0 !important;
  }

  .ordersm-1 {
    order: 1 !important;
  }

  .ticket-d-none {
    display: none !important;
  }
}