.cart {
  padding: 30px !important;
  flex-direction: column;
  border-radius: 16px;
  background: #FFFFFF;
}

.cart-heading {
  color: #101828;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 177.778% */
  letter-spacing: 0.25px;
}

.cart-description {
  color: #475467;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
}

.order-box {
  height: fit-content;
  padding: 30px !important;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
  background: #FFFFFF;
  width: 40% !important;
}

.event-order-box {
  height: fit-content;
  /* padding: 30px !important; */
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
  /* background: #f5f5f5; */
  /* width: fit-content !important; */
}

.org-head {
  color: #024ddf;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
}

.org-box {
  display: inline-flex;
  padding: 20px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  border-radius: 10px;
  border: 1.7px solid rgba(2, 77, 223, 0.25);
  background: #fff;
}

.org-box img {
  width: 120px;
  height: 120px;
  border-radius: 100%;
}

.org-box h6 {
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 0px;
  /* margin-left: 24px; */
}

.org-box h5 {
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 0px;
}

.org-box p {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 0px;
}

.otherorg img {
  width: 75px;
  height: 75px;
}

.otherorg h5 {
  color: #121212;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.order-box h5 {
  color: #121212;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.event-order-box h5 {
  color: #121212;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.total-count h5 {
  color: #121212;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
}

.total-count h6 {
  color: #121212;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.book-now {
  display: flex !important;
}

.under-ticket img {
  width: 150px;
  height: 150px;
}

.under-ticket1 p {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.quantity {
  display: flex;
  width: 80px;
  padding: 2px 0px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.quantity button {
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 18px;
  /* width: 30px; */
  /* height: auto; */
  text-align: center;
  transition: background-color 0.2s;
}

.quantity button:hover {
  background-color: white;
}

.input-box {
  width: 30px;
  text-align: center;
  border: none;
  padding: 2px 0px;
  outline: none;
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

/* Hide the number input spin buttons */
.input-box::-webkit-inner-spin-button,
.input-box::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pass {
  color: #121212;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-bottom: 0px !important;
  margin-left: 16px !important;
}

.admission {
  color: #024DDF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 16px !important;
}

.mega-pass {
  color: #d3a620;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 16px !important;

}

.normal-dupage-pass {
  color: #024DDF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 5px !important;

}

.flash {
  border-radius: 20px;
  border: 1px solid #024DDF;
  animation: animate 1s linear infinite;
  max-width: 60%;
  align-items: center;
  margin-left: 4px;
}

.flash small {

  animation: animate_small 1s linear infinite;

}

.normal-pass {
  color: #024DDF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 16px !important;
}

.description-text {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 16px !important;
}

.description-text-red {
  color: #e30808;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 16px !important;
}

.form-select {
  width: 50% !important;

  min-width: 50% !important;
}

.processing-fees {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.promocode {
  color: #024ddf;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  cursor: pointer;
}

.amountpay {
  color: #024ddf;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.totalpay {
  color: #121212;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.ticket-type-box {
  min-width: 40%;
}

.proceedbtn {
  padding: 12px 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  background-color: black;
  border: none;
  outline: none;
  border-radius: 10px;
  margin-top: 18px;
}

.proceedbtn-dis {
  padding: 12px 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  background-color: rgb(79, 76, 76);
  border: none;
  outline: none;
  border-radius: 10px;
  margin-top: 30px;
  cursor: not-allowed;
}

.proceedbtn a {
  color: white;
  text-decoration: none;
}

.earlyBirdText {
  color: #23BA20;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 23px;
  margin-left: 16px;
}

.paycharge {
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.paycharge-early {
  color: #23BA20;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-align: end;
}

.convcharge {
  color: var(--Black, #000);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.data-organiser {
  display: flex;
  justify-content: space-between;
}

.dupage-totals {
  color: #000;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.25px;
}

.hr-dupage {
  border: 2px solid #024DDF;
}

.hr-ticket {
  border-top: 3px dashed blue;
}

.free {
  background-color: green;
  width: 40%;
  text-align: center;
  text-justify: center;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.25px;
}

.required-sign {
  color: #E45D09;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: 2px;
}

.localpass {

  width: 30px;
  height: 30px;

}

.price-quantity {

  min-width: 47%
}

.earlyBirdTextMegaPass {
  color: #23BA20;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  margin-left: 16px;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animate_small {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 430px) {

  .earlyBirdTextMegaPass {

    margin-left: 0px;
  }
}

@media (max-width:576px) {
  .book-now {
    display: none !important;
  }
}

@media (max-width: 768px) {

  .earlyBirdTextMegaPass {

    margin-left: 6px;
  }

  .under-ticket img {
    width: 150px;
    height: 150px;
  }

  .order-box {
    width: 100% !important;
  }

  .org-box {
    display: block;
  }

  .data-organiser {
    flex-wrap: wrap !important;
    justify-content: normal !important;
  }

  .otherorg {
    margin-top: 10px;
    margin-right: 10px;
  }

  .event-order-box {
    height: fit-content;
    /* padding: 14px !important; */
  }

  .flash {
    max-width: 72%;
    margin-left: 12px;
  }

  .mega-pass {
    font-size: 16px;
    margin-left: 5px !important;
  }

  .admission {
    font-size: 16px;
    margin-left: 4px !important;
  }

  .localpass {

    width: 20px;
    height: 20px;

  }

  .description-text {

    margin-left: 4px !important;
  }

  .price-quantity {
    min-width: 50%
  }

  .ticket-price {
    min-width: 30%;
  }

  .ticket-type-box {
    min-width: 60%;
  }

  .normal-dupage-pass {
    color: #024DDF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 5px !important;

  }
}