.profile-box h3 {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 0px;
  margin-right: 12px;
}
.page-box {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.profile-box div {
  width: 42px;
  height: 42px;
  background-color: #d9d9d9;
  color: var(--Black, #000);
  border-radius: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tickets-count img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}
.ticket-col-box {
  width: 440px !important;
  padding-right: 0px !important;
}
.tickets-count {
  width: 205px;
  height: 85px;
  border-radius: 10px;
  background: var(--White, #fff);
  display: flex;
  align-items: center;
  padding: 10px;
}
.info1 {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.25px;
  margin-bottom: 0px;
}
.door-btn {
  border-radius: 10px;
  background: #024ddf;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 18px;
  border: none;
  outline: none;
  margin: auto;
}
.report-btn {
  padding: 8px 20px;
  border-radius: 10px;
  background: #121212;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  outline: none;
}
.ticket-types {
  width: 75px;
  height: 3px;
  border-radius: 10px;
  margin-bottom: 6px;
}
.ticket-para {
  color: var(--Black, #000);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
  margin-bottom: 6px;
}
.padding-box {
  padding: 0px !important;
}
.ts-box1 {
  width: 49% !important;
  border-radius: 10px;

}

.ts-box2 {
  width: 49% !important;
  border-radius: 10px;
}
.ts-box2 canvas{
  height: 300px !important;
  width: 100% !important;
  padding: 12px 14px 10px 14px;
  margin-top: 4px;
}
.ts-box-dm-1 {

  border-radius: 10px;
  width: 70%;
}
.dollar{
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  /* transform: rotate(70deg); */
}
.ticket-head-box {
  height: fit-content !important;
  align-items: center;
}

.ticket-head-box select:focus {
  outline: none;
}
.ticket-head-box option::target-text {
  color: #024ddf !important;
  
}
.ticket-head-box h4 {
  color: var(--Black, #000);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.44px;
  margin-bottom: 0px;
}
.ticket-head-box div {
  border-radius: 8px;
  border: 1px solid rgba(2, 77, 223, 0.5);
  color: #024ddf;
  font-size: 14px;
  padding: 6px 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
}
.select-div{
      border-radius: 8px;
      border: 1px solid rgba(2, 77, 223, 0.5);
      color: #024ddf;
      font-size: 14px;
      padding: 6px 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.28px;
      min-width:35%
    
}
.select-width{
  width: 90%;
}
.select-div select:focus {
  outline: none;
}

.select-div option::target-text {
  color: #024ddf !important;

}
.ticket-sold h1 {
  color: #024ddf;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.28px;
  text-align: center;
  margin-bottom: 18px;
}
.ticket-sold p {
  color: var(--Black, #000);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.44px;
  text-align: center;
}
.ticket-sold {
  width: 340px;
  padding: 40px 15px;
  border-radius: 10px;
  background: #deecff;
  margin-right: 1rem;
}
.mega-pass-dash {
  padding: 15px 15px;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 110px;
}
.mega-pass-past {
  padding: 15px 15px;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 110px;
}
.mega-pass-past h1 {
  color:#000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
}
.mega-pass-dash-live {
  padding: 7.5px 15px;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
  color: #024ddf;
  width: 110px;
  background: #deecff;
}
.mega-pass-dash-past {
  padding: 7.5px 15px;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
  color:#024ddf;
  border:1px solid #024ddf;
  width: 110px;
 
}
.mega-pass-dash-past h1 {
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
}

.mega-pass-dash-past p {
  color: var(--Black, #000);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
}

.mega-pass-dash-live h1 {
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
}

.mega-pass-dash-live p {
  color: var(--Black, #000);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
}
.ticket-sold-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 12px;
}
.mega-pass-dash h1 {
  color: var(--White, #fff);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
}
.mega-pass-dash p {
  color: var(--Black, #000);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
}
.mega-pass-past p {
  color: var(--Black, #000);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
}
.revenue-head {
  font-size: 16px;
  font-weight: 600;
}
.pie-chart {
  width: 185px;
  height: 185px;
}

.dupage-img {
  height: 145px !important;
}
.line-chart{
  width: 100% !important;
}
.assign-door{
  height: 219px !important;
}
.white-box{
  background-color: #fff;
  border-radius: 10px;
}
.header-button-dash{
border: #595858;
border-right: #000;
margin: 2px;
border-radius: 10px 0 0 10px;
padding: 10px 30px 10px 30px;
background-color:#fff;
}
.header-button-door {
  border: #595858;
  margin: 2px;
  border-radius: 0 10px 10px 0;
  padding: 10px 30px 10px 30px;
  background-color: #fff;
}
.button-clicked, .header-button-dash:hover, .header-button-door:hover{
  background-color:#024DDF;
  color:#fff
}
.blinking-green {
  animation: 1s blink-green ease infinite;
  width: 15px;
  height: 15px;
  border-radius: 100%
}
.child-desc{
  color: var(--Black, #000);
  margin-bottom:0;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.section-header{
  color:  #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.44px;
}
.icon-box-dashboard {
  padding: 5px;
  border-radius: 10px;
  background: #e9ecf0;
  width: 40px;
  height: 40px;
  align-items: center;
}
@keyframes blink-green {

  from,
  to {
    opacity: 0;
    background-color: green;
  }

  50% {
    opacity: 1;
  }
}
@media only screen and (max-width: 1500px) {
  .ticket-col-box {
    width: 375px !important;
    padding-right: 0px !important;
  }
  .tickets-count {
    width: 170px;
    height: 75px;
  }
  .ticket-count-data h6 {
    font-size: 14px;
    line-height: 20px;
  }

  .ticket-count-data p {
    font-size: 12px;
    line-height: 20px;
  }
  .tickets-count img {
    width: 40px;
    height: 40px;
  }
  .info1 {
    font-size: 12px;
    line-height: 18px;
  }
  .dupage-img {
    height: 139px !important;
  }
  .assign-door {
    height: 211px !important;
  }
  .door-btn {
    padding: 10px 16px;
  }
  .ticket-sold {
    width: 300px;
    padding: 30px 5px;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1300px) {
  .ticket-col-box {
    width: 325px !important;
    padding-right: 0px !important;
  }
  .info1 {
    font-size: 13px;
    line-height: 19px;
  }
  .tickets-count {
    width: 148px;
  }
  .ticket-types {
    height: 2px;
    margin-bottom: 4px;
  }
  .ticket-para {
    font-size: 10px;
  }
  .pie-chart {
    width: 165px !important;
    height: 178px !important;
  }
  .assign-door {
    height: 225px !important;
  }
  .door-btn {
    font-size: 12px;
  }
  .mega-pass-dash {
    padding: 3px 2px;
    border-radius: 10px;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 85px;
  }
  .ticket-sold {
    width: 290px;
    padding: 32px 5px;
    border-radius: 10px;
  }
  .ts-box2 canvas{
    height: 203px !important;
    width: 100% !important;
    padding: 12px 14px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .ticket-col-box {
    width: 280px !important;
    padding-right: 0px !important;
  }
  .ticket-count-data p {
    font-size: 10px;
    line-height: 15px;
  }
  .ticket-count-data h6 {
    font-size: 12px;
    line-height: 15px;
  }
  .tickets-count img {
    width: 25px;
    height: 25px;
  }
  .tickets-count {
    width: 128px;
    height: 60px;
  }
  .revenue-head {
    font-size: 12px;
    font-weight: 600;
  }
  .ticket-para {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 5px;
  }
  .ticket-types {
    width: 65px;
    height: 2px;
    border-radius: 5px;
    margin-bottom: 3px;
  }
  .pie-chart {
    width: 140px !important;
    height: 185px !important;
  }
  .dupage-img {
    height: 120px !important;
  }
  .info1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 0px;
  }
  .door-btn {
    font-size: 12px;
    padding: 12px 3px;
  }
  .assign-door {
    height: 200px !important;
  }
  .report-btn {
    padding: 8px;
    font-size: 14px;
  }
  .ticket-sold-box {
    display: block;
    margin-right: 0px;
  }
  .ticket-sold {
    width: 100%;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1000px) {
  .page-box {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .ticket-col-box {
    padding-right: 0.8rem !important;
    width: 100% !important;
  }
  .tickets-count {
    width: 49%;
  }
  .padding-box {
    padding: 0px 10px !important;
  }
  .ts-box1 {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .ts-box2 {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .ticket-sold {
    width: 110px;
    padding: 10px 0px;
    gap: 10px;
  }
  .ticket-sold h1 {
    font-size: 24px;
  }
  .ticket-sold p {
    font-size: 14px;
  }
    .mega-pass-dash {
      padding: 20px 15px;
      
    }
  .mega-pass-dash h1 {
    font-size: 24px;
  }
  .mega-pass-dash p {
    font-size: 14px;
  }
  .dupage-img {
    width: 100%;
  }
  .ticket-sold-box {
    display: block;
    margin-right: 0px;
  }
  .ticket-sold {
    width: 100%;
    margin-bottom: 15px;
  }
    .ts-box-dm-1 {
  
      border-radius: 10px;
      width: 90%;
    }
        .select-div {
          
          width: 85%
        }
    
        .select-width {
          width: 85%;
        }
}
@media only screen and (max-width: 400px) {
.mega-pass-dash-live {
    padding: 1.5px 15px;
    width: 92px;
  }
  .mega-pass-dash {
    width: 92px;
    padding: 15px 5px;
}
.ts-box2 canvas {
  height: 80% !important;
  width: 100% !important;
  padding: 0 !important;
}
}