.error-404 {
    font-size: 16px;
    line-height: 24px;
    color: #BC1B06;
    font-weight: 600;
}

.page-not-found {
    font-size: 60px;
    line-height: 72px;
    color: #101828;
    font-weight: 600;
}

.page-not-desc {
    font-size: 20px;
    line-height: 30px;
    color: #475467;
    font-weight: 400;
}

.go-back {
    padding: 16px 22px;
    background-color: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px #1018280D;
    font-size: 18px;
    line-height: 28px;
    color: #344054;
    text-decoration: none;
    font-weight: 600;
}

.go-home {
    border: 2px solid #FFFFFF1F !important;
    color: #000000 !important;
    background-color: #F98A45 !important;
}

.w-35 {
    width: 35% !important;
}

@media (max-width: 1200px) {
    .w-35 {
        width: 50% !important;
    }
}

@media (max-width: 900px) {
    .w-35 {
        width: 100% !important;
    }
}