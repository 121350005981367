.notification-card {
    padding: 20px 16px;
    background-color: #F9FAFB;
    border-radius: 8px;
}

.left-side-title {
    font-size: 14px;
    line-height: 20px;
    color: #E45D09;
    font-weight: 600;
}

.check-status {
    padding: 2px 8px;
    background-color: #FEF3F2;
    border: 1px solid #FECDCA;
    color: #BC1B06;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border-radius: 9999px;
}

.notification-details .notification-title {
    color: #101828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

.notification-details .notification-desc {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    font-weight: 400;
}

.dismiss-notification {
    color: #475467;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

.read-notification {
    color: #E45D09;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}