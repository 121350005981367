.signup-title {
    color: #101828;
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    text-align: center;
}

.signup-desc {
    color: #475467;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
}

.signup-label {
    font-size: 14px !important;
    line-height: 20px !important;
    color: #344054 !important;
}

.signup-input-field {
    background-color: #FFFFFF !important;
    border: 1px solid #D0D5DD !important;
    border-radius: 16px !important;
    box-shadow: 0px 1px 2px 0px #1018280D !important;
    padding: 10px 14px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #667085 !important;
    font-weight: 400 !important;
}

.get-started {
    color: #FFFFFF !important;
    background-color: #F98A45 !important;
    box-shadow: 0px 1px 2px 0px #1018280D !important;
    border: 2px solid #FFFFFF1F !important;
    border-radius: 16px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
    padding: 10px 16px !important;
}

.already-account {
    color: #475467 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
}

.already-account a {
    text-decoration: none;
}

.login-link {
    color: #E45D09 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
}

.signup-btn-disable {
    color: #FFFFFF !important;
    background-color: #F98A45 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.8;
    border: 2px solid #FFFFFF1F !important;
    border-radius: 16px !important;
    padding: 10px 110px;
    gap: 5px;
    position: relative;
}

.signup-btn {
    padding: 10px 110px;
    gap: 5px;
    border: 2px solid #FFFFFF1F !important;
    border-radius: 16px !important;
    color: #FFFFFF !important;
    background-color: #F98A45 !important;
    outline: none;
    opacity: 1;
    position: relative;
}

.loading-btn {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    color: #fff !important;
    padding: 10px 110px !important;
    gap: 5px !important;
    border-radius: 10px !important;
    color: #FFFFFF !important;
    background-color: #F98A45 !important;
    border: none !important;
    outline: none !important;
    pointer-events: none !important;
    width: 100%;
    cursor: not-allowed !important;
}


/* Old CSS */
/*
.signup-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
}

.signup-page img {
    width: 300px;
    height: 49.398px;
    margin-bottom: 12px;
}

.signup-page h6 {
    color: #121212;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.signup-page p {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
}

.signup-label {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}

.signup-input {
    padding: 14px 14px;
    border-radius: 10px;
    border: 0.8px solid rgba(0, 0, 0, 0.73);
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    width: 100%;
    margin-bottom: 12px;
}

.signup-input-password {
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    border: hidden;
    width: 95%;
    margin-bottom: 12px;
}

.signup-input-name {
    padding: 14px 14px;
    border-radius: 10px;
    border: 0.8px solid rgba(0, 0, 0, 0.73);
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    width: 100%;
    margin-bottom: 12px;
}

.signup-page h5 {
    color: #121212;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-top: 14px;
}

.signup-page h5 span {
    color: #024ddf;
    text-decoration-line: underline;
} */