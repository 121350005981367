body {
  background-color: #f9f4f0;
}

.new-bg {
  background-color: #f9f4f0;
}

.margin {
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.my-32 {
  margin: 32px 0;
}

.new-card-design {
  background: #FFFFFF;
  border-radius: 12px;
  box-sizing: border-box;
}

.event-category {
  text-decoration: none;
  color: #475467;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.event-type {
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 10px;
}

.event-type ul {
  list-style: none;
  display: inline-flex;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  gap: 16px;
}

.event-type ul li {
  list-style-type: none;
  flex: 0 0 auto;
  width: 80px;
}

.event-type-img {
  max-width: 80px;
  height: auto;
  object-fit: contain;
}

.event-category {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}

/* .active-event-category img {
  filter: brightness(0) saturate(100%) invert(45%) sepia(98%) saturate(570%) hue-rotate(5deg) brightness(102%) contrast(95%);
} */

.active-event-category span {
  color: #E45D09 !important;
}

.event-type-img {
  padding: 20px;
  width: 32px;
}

.event-desc {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #475467;
}

.home-title {
  line-height: 24px;
  font-size: 16px;
  font-family: 500;
  margin-right: 1px;
  color: #000000;
}

.icon-color {
  color: #667085 !important;
}

.event-minus {
  margin-top: -5px;
}

.home-select-country {
  background: none;
  color: #BC1B06;
  border: none;
  margin-bottom: 3px;
}

.home-select-country:focus-visible {
  outline: none;
}

.home-slider-box .slick-slider {
  overflow: visible;
}

.home-slider-box .slick-slide {
  margin-right: 20px;
}

.home-slider-box .full-width-slide {
  width: calc(100% - -5px) !important;
}

.home-slider-box .slick-slide img {
  height: 310px;
  border-radius: 10px;
}

.home-slider-box .slick-dots {
  bottom: 10px;
}

.home-slider-box .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 7px;
  transition: 0.25s ease-out;
  opacity: 0.75;
  background: #e9ecf0;
  border-radius: 50%;
  border: 1px solid #282828;
}

.home-slider-box .slick-dots .slick-active {
  background-color: #024ddf;
  border: none;
}

.home-slider-box .slick-dots li button:before {
  content: "" !important;
}

.home-slider-box .slick-prev {
  left: 70px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  z-index: 5;
  fill: rgba(255, 255, 255, 0.8);
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.home-slider-box .slick-prev:before {
  content: "";
  background-image: url("../../../Assets/left.png");
  background-size: cover;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.home-slider-box .slick-next {
  right: 70px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  z-index: 5;
  background-color: white;
  fill: rgba(255, 255, 255, 0.8);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.home-slider-box .slick-next:before {
  content: "";
  background-image: url("../../../Assets/right.png");
  background-size: cover;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.home-slider-box .slick-prev:hover,
.home-slider-box .slick-prev:focus,
.home-slider-box .slick-next:hover,
.home-slider-box .slick-next:focus {
  background-color: white;
}

.banner-title {
  color: #101828 !important;
  line-height: 24px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.banner-date-day {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #344054;
}

.banner-text {
  background: #024ddf;
  padding: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
}

.banner-img-box {
  height: 160px;
  width: 300px;
  position: relative;
}

.banner-img-box h4 {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  color: #121212;
}

.rounded-12 {
  border: none !important;
  border-radius: 12px !important;
  box-shadow: 0px 8px 30px -2px #1018280D;
}

.artist-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #344054;
}

.category-head {
  color: #121212;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
}

.tour-head {
  color: #121212;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  padding: 0px !important;
  margin: 30px 0px !important;
}

.tour-box {
  position: relative;
}

.tour-box h5 {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  background: #024ddf;
  padding: 8px 30px;
}

.tour-box p {
  color: #121212;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  margin: 8px 0px 0px 0px !important;
}

.tour-box span {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.load-more-btn {
  padding: 8px 14px;
  border-radius: 10px;
  background: #024ddf;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  outline: none;
  border: none;
  width: fit-content;
  margin: 40px 0px;
}

.window-box h1 {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: normal;
  margin-top: 35px;
}

.window-box p {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.window-box button {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  border: none;
  background-color: transparent;
  outline: none;
  margin-top: 75px;
}

.category-box {
  display: flex;
}

.banner-img-box {
  margin-right: 1.5rem;
}

.tour-box {
  padding: 0px;
}

@media screen and (max-width: 1200px) {
  .search-new-home {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 95% !important;
    padding: 2px 16px;
    border: 1px solid #D0D5DD;
    border-radius: 16px;
    background: #ffffff;
  }
}

@media screen and (max-width: 1024px) {
  .home-slider-box .slick-slide {
    margin-right: 0px;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 768px) {
  .banner-text {
    padding: 12px;
    font-size: 15px;
    line-height: 20px;
  }

  .slick-slide .banner-img {
    height: 228px !important;
  }

  .home-slider-box .slick-slide img {
    height: 150px;
  }

  .home-slider-box .slick-slide {
    margin-right: unset;
    padding: 7px;
  }

  .category-head {
    margin-top: -30px !important;
  }

  .category-box {
    display: block;
  }

  .banner-img-box {
    height: fit-content;
    width: fit-content;
    margin: 10px 0px !important;
  }

  .tour-head {
    padding-left: 10px !important;
  }

  .tour-box {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
  }

  .tour-box h5 {
    top: 14px;
    left: 28px;
    font-size: 12px;
    padding: 8px 14px;
  }

  .tour-box p {
    font-size: 12px;
  }

  .tour-box span {
    font-size: 12px;
  }

  .load-more-btn {
    padding: 8px 10px;
    font-size: 12px;
    margin: 10px 0px;
  }

  .window-box h1 {
    font-size: 24px;
    margin-top: 5px;
  }

  .window-box p {
    font-size: 16px;
    margin-top: 14px;
  }

  .window-box button {
    font-size: 16px;
    margin-top: 20px;
  }
}


/* New Home */
.banner-location {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #344054;
  margin: 0;
  display: flex;
  align-items: center;
}

.banner-tag {
  position: absolute;
  top: 8px;
  left: 20px;
  padding: 4px 26px;
  gap: 10px;
  border-radius: 10px;
  background: #024ddf;
  color: white;
}

.event-gem-title {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.event-gem-title span {
  color: #024DDF;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.event-gem-subtitle {
  font-size: 16px;
  text-align: center;
  padding-top: 15px;
}

.grey-bg {
  background-color: #E9ECF0;
  padding-top: 30px;
  padding-bottom: 30px;
}

.thumbnail {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.thumbnail p {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.hover-text {
  display: none;
  font-size: 12px;
  font-weight: 400;
  transition: .5s ease-in;
}


.hover-text p {
  font-weight: normal;
  font-size: 14px;
  text-align: left;
}

.hover-text ul {
  margin-left: 0;
}

.fade-out-div {
  width: 150px;
  height: 150px;
  margin: auto;
  cursor: pointer;
}

.fade-out-div:hover {
  width: 100%;
  height: 150px;


}

.fade-out-div:hover img {
  transition: .5 ease-out;
  display: none;

}

.fade-out-div:hover .hover-text {
  display: block;
  opacity: 1;

}

.slick-arrow.slick-next {
  right: 25px;
}

.slick-arrow.slick-prev {
  left: 25px;
  z-index: 10;
}

.sliderCont {
  padding-top: 30px;
  padding-bottom: 30px;
}

.margin-auto {
  margin: auto;
}

.features {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgb(251, 250, 250);
  border-radius: 10px;
}

.features:hover .overlay {
  opacity: 1;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.text {
  margin: 1px;
  color: black;
  font-size: 16px;
  top: 50%;
  text-align: start;
}

.banner-images {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.main-image {
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.5);
}

.event-text-head {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.event-second-head {
  color: #656363;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.badges {
  width: 150px;
  height: 30px;
}

@media(max-width:430px) {
  .dupage-banner {
    height: 80%;
  }
}

/* Custom Scrollbar Styling */
.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #777;
}