.verification-text {
    color: #475467;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.reset-email {
    color: #e45d09;
    text-decoration: none;
}

.reset-email:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: #e45d09;
}

.mail-bg {
    background-color: #F9F4F0;
    border-radius: 12px;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding: 10px;
    border: 1px solid #E4E7EC;
}

.otp-inputs div input {
    width: 80px !important;
    height: 80px !important;
    border-radius: 12px;
    padding: 8px;
    border: 2px solid #BC1B06;
    background-color: #F9F4F0;
    outline: none;
    margin: 0px 10px;
    color: #F98A45;
    font-size: 48px;
    font-weight: 500;
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.otp-inputs div input:focus-within {
    box-shadow: 0px 0px 0px 2px #DC1919;
}

.otp-inputs div span {
    display: none;
}

.receive-email {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    font-weight: 400;
}

.resend-link {
    font-size: 14px;
    line-height: 20px;
    color: #E45D09;
    font-weight: 600;
    cursor: pointer;
}

.resend-link-inactive {
    font-size: 14px !important;
    line-height: 20px !important;
    color: #E45D09 !important;
    font-weight: 600 !important;
    cursor: not-allowed;
    opacity: 0.8 !important;
}

.disabled-link {
    pointer-events: none;
}


/* .otp-inputs span {
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
} */

.forgot-label {
    color: #121212;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
}

.forgot-modal h2 {
    color: #024ddf;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 47px;
}

.forgot-modal p {
    color: #121212;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.forgot-modal img {
    height: 320px;
    width: 320px;
}

.verified-email {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    color: #101828;
    text-align: center;
}

.verified-email-desc {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    font-weight: 400;
}