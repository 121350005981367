.dashboard-text {
  color: #475467;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.title-text {
  background-color: #F9FAFB;
  padding: 4px 8px;
  border: 0;
  border-radius: 6px;
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.page-title {
  color: #101828;
  font-size: 30px;
  line-height: 38px;
  font-weight: 600;
}

.nav-link-overview {
  padding: 8px 12px !important;
  cursor: pointer;
}

.nav-link-active {
  border-left: 2px solid #F98A45;
}

.mobile-profile-options {
  display: flex;
}

.personal-info {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.personal-info-desc {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.profile-card {
  box-shadow: 0px 1px 2px 0px #1018280D;
  border: 1px solid #E4E7EC;
  background: #FFFFFF;
  border-radius: 12px;
}

.form-label-profile {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.form-control-profile {
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  padding: 10px 14px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #1018280D;
  color: #101828;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.contact-support {
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px 0px #1018280D;
  padding: 10px 14px;
  border-radius: 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #344054;
}

.nav-link {
  color: #fff !important;
}

.nav-pills .nav-link {
  width: 350px;
  height: 54px;
  padding: 14px 0px 16px 0px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  background-color: white;
  color: #121212 !important;
  outline: #121212;
  border-top: 1px solid rgba(18, 18, 18, 0.2) !important;
  border-bottom: 1px solid rgba(18, 18, 18, 0.2) !important;
  border-right: 1px solid rgba(18, 18, 18, 0.2) !important;
  border-left: 1px solid rgba(18, 18, 18, 0.2) !important;
}

.form-control:focus {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.no-focus-shift {
  outline: none;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.no-focus-shift:focus {
  width: auto;
}

.no-focus-shift input {
  margin: 0 !important;
  padding: 0 !important;
}

.navbar a:focus {
  color: #000000;
  background-color: #fff;
}

.offcanvas-body {
  background-color: #024ddf;
}

.order-data {
  margin-bottom: 10px;
}

.order-data h5 {
  color: #121212;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  margin-bottom: 8px;
}

.order-data h6 {
  color: #121212;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
}

.event-order-box1 {
  height: fit-content;
  padding: 20px !important;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
  background: #f5f5f5;
}

.ticket-download-btn {
  color: white !important;
  background-color: #f98a45;
  padding: 10px 14px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 16px;
  outline: none;
  border: none;
}

.mail-id {
  color: #475467;
  text-decoration: none;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.qr-show {
  height: 40%;
}

.slide-main-box .slick-dots {
  bottom: -25px;
  margin-top: 2px;
}

.no-ticket {
  height: 60vh;
}

.my-data a {
  line-height: 20px;
  padding: 0px;
}

.my-data a li {
  list-style-type: none;
  margin-left: 15px;
}

.data-nav-toggle {
  color: #344054;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.navbar-profile,
.navbar-mobile-profile {
  background-color: #FFFFFF;
  padding: 10px 14px;
  border: 1px solid #D0D5DD;
  border-radius: 16px;
  box-shadow: 0px 1px 2px 0px #1018280D;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
}

.navbar-profile ul {
  opacity: 0;
}

.navbar-mobile-profile ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  font-size: 14px;
  font-weight: 400;
  background-color: #FFFFFF;
  position: absolute;
  top: 45px;
  padding: 0px;
  left: 0;
  margin: 0;
  border: 1px solid #D0D5DD;
  border-radius: 16px;
  z-index: 10;
}

.navbar-mobile-profile ul li {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
}

.navbar-mobile-profile ul li a {
  text-decoration: none;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #344054 !important;
  font-weight: 600 !important;
}

@media (max-width: 991px) {
  .data-nav-toggle {
    display: block;
  }

  .navbar-profile ul {
    display: none;
  }
}