.app-wrapper {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 25px 55px;
  background: #e9ecf0;
}

.app-wrapper1 {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 25px 20px;
  background: #e9ecf0;
}

.mimum-80 {
  min-height: 85vh;
  height: 100%;
}

.dash-head {
  color: #121212;
  font-size: 24px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.48px;
}

.ticket-count {
  display: flex;
  padding: 20px 15px;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
  /* width: 212px !important; */
}

.ticket-count1 {
  display: flex;
  padding: 20px 15px;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
  width: 205px !important;
}

.ticket-count img,
.ticket-count1 img {
  height: 54px;
  width: 54px;
}

.ticket-count-data h6 {
  color: #024ddf;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 0px;
}

.ticket-count-data p {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.28px;
  margin-bottom: 0px;
}

.event-count {
  width: 400px !important;
  height: 390px;
  border-radius: 10px;
  background: #fff;
  margin: auto;
}

.ticket-status {
  height: 280px;
  border-radius: 10px;
  background: #fff;
}

.social-media {
  width: 860px !important;
  border-radius: 10px;
  background: #fff;
  height: 280px;
}

.social-media1 {
  width: 840px !important;
  border-radius: 10px;
  background: #fff;
  height: 280px;
}

.event-count h5 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.44px;
  padding: 10px;
}

.ticket-status h5 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.44px;
  padding: 10px;
}

.social-media h5,
.social-media1 h5 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.44px;
  padding: 10px;
}

.noevent {
  margin-top: 100px;
  color: #024ddf;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.32px;
}

.load-more-btn {
  padding: 8px 8px;
  border-radius: 10px;
  background: #e0e0e0;
  color: #c6c6c6;
  font-size: 14px;
  font-weight: 500;
  margin-top: 100px;
}

.create-event {
  display: flex;
  border-radius: 10px;
  background: #fff;
  padding-right: 0px !important;
}

.create-event img {
  width: 236px;
  height: 236px;
}

.event-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.event-icon-box h6 {
  color: #024ddf;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 30px;
}

.event-icon-box .icon {
  width: 80px;
  height: 80px;
  background-color: #024ddf;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
}

.task-drop {
  border: 0.5px solid rgba(18, 18, 18, 0.4);
  width: 28px;
  height: 28px;
  color: #024ddf;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-head {
  color: #121212;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: -0.32px;
  margin-bottom: 0px;
}

.task {
  width: 860px !important;
  border-radius: 10px;
  background: #fff;
  height: 433px;
}

.task h5 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.44px;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .app-wrapper {
    padding: 25px 0px;
  }
}

/* @media (min-width: 1200px) {
  .app-wrapper {
    margin-left: 100px;
    transition: all 0.4s ease-in-out;
  }
  .app-wrapper1 {
    margin-left: 200px;
    transition: all 0.4s ease-in-out;
  }
} */