.terms-title {
    font-size: 20px;
    line-height: 28px;
}

.text-justify {
    text-align: justify;
}

.text-center {
    text-align: center;
}

.custom-link-terms {
    color: #BC1B06;
    text-decoration: none;
}

.terms-list li {
    margin: 2px 0;
}

.terms-list li,
.terms-list a {
    color: #BC1B06;
    text-decoration: none;
}