.title-payment {
  color: #101828;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.success-para {
  color: #475467;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.title-failed {
  /* color: #df0202; */
  color: #BC1B06;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.25px;
  margin-bottom: 0px;
}

.confirm-booking {
  box-shadow: 0px 8px 30px -2px #1018280D;
  background-color: #FFFFFF;
}


.order-title {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.order-description {
  color: #344054;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.order-quantity {
  width: 5px;
  height: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  border: 1px solid #D0D5DD;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 2px 0px #1018280D;
  border-radius: 16px;
  padding: 11px 11px;
  text-align: center;
}

.paid-amount {
  color: #E45D09 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.real-price {
  position: absolute;
  bottom: 0;
  text-decoration: line-through;
  right: 47px;
  font-size: 13px !important;
}

.event-border {
  width: 0.7px !important;
  height: 800px;
  background: #c6c6c6;
}

.signup-event-border {
  width: 0.7px !important;
  height: 760px;
  background: #c6c6c6;
}

.under-ticket1 h6 {
  color: #101828;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.booking-data {
  color: #344054;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.booking-details {
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 16px;
}

.ticketcharge {
  border: 1px solid #E4E7EC;
  padding: 16px;
  margin-top: 14px;
  border-radius: 12px;
}

.ticketcharge p {
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.processcharge p {
  color: #344054;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.processcharge h6,
.processcharge h4 {
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.under-ticket1 p {
  color: #121212;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.payment-head {
  color: #121212;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.passes-data img {
  width: 24px;
  height: 24px;
}

.passes-data h6 {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 10px;
}

.form-check-box {
  border-radius: 10px;
  border: 0.7px solid rgba(18, 18, 18, 0.7);
  width: 300px;
  padding: 10px 15px;
  margin-bottom: 20px;
  margin-right: 1rem;
}

.payment-box {
  border-radius: 10px;
  border: 0.5px solid rgba(18, 18, 18, 0.2);
  padding: 15px;
}

.payment-img {
  width: 100px !important;
  height: 100px !important;
}

.qrcode-box img {
  width: 100px;
  height: 100px;
}

.qrcode-box h6,
.qrcode-box h6 a {
  text-decoration: none;
  color: #475467;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.form-check-box label span {
  color: #121212;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.form-check-box label img {
  width: 90px;
  height: 20px;
  margin-left: 10px;
}

.payment-border {
  width: -webkit-fill-available;
  height: 1px;
  background-color: #c6c6c6;
  margin: 10px 0px;
}

.proceedbtn {
  color: #FFFFFF;
  line-height: normal;
  background-color: #F98A45;
  border: 2px solid #FFFFFF1F !important;
  border-radius: 16px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  padding: 10px 16px !important;
  outline: none;
  margin-top: 18px;
}

.proceedbtn a {
  color: white;
  text-decoration: none;
}

.amountpay-sucess {
  color: #024ddf;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.container {
  position: relative;
}

.animation-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  z-index: 1;
  transition: transform 0.5s ease-in-out;
  pointer-events: none;
}

.container.show-animation .animation-container {
  transform: translateX(-50%) translateY(0);
}

.payment-content {
  position: relative;
  z-index: 5;
  padding-top: 100px;
}


@media screen and (max-width: 768px) {
  .form-check-box label span {
    font-size: 12px;
  }

  .form-check-box {
    padding: 10px 5px;
    margin-right: 5px;
  }

  .animation-container {
    width: 70%;
    bottom: 10%;
  }

  .payment-content {
    padding-top: 150px;
  }
}

@media (max-width: 480px) {
  .animation-container {
    width: 80%;
    bottom: 20%;
  }

  .payment-content {
    padding-top: 180px;
  }
}

@media (min-width: 1200px) {
  .animation-container {
    width: 40%;
    bottom: 5%;
  }

  .payment-content {
    padding-top: 100px;
  }
}