body {
    background-color: #F9F4F0;
}

.first-line-links a {
    color: #475467;
    text-decoration: none;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
}

.first-line-icon {
    color: #475467;
}

.min-width-400 {
    min-width: 400px;
}

.right-side {
    border: 1px solid #475467;
}

.ticket-data {
    border: 1px solid #E4E7EC;
    border-radius: 12px;
    padding: 13px;
}

.left {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #344054;
}

.bg-none {
    background: none;
    color: #475467;
}

.card-heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
}

.card-title {
    font-weight: 500;
}

.card-desc {
    font-weight: 400;
    font-size: 12px;
}

.right {
    font-size: 14px;
    color: #344054;
}

.venue-desc {
    color: #344054;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding-left: 20px;
}

.book-now:disabled,
.book-now:disabled:hover {
    pointer-events: none;
}

.rounded-sm {
    border-radius: 10px !important;
}

.price-data {
    color: #E45D09 !important;
}

.ticket-quantity {
    border: 1px solid #D0D5DD;
    padding: 5px;
    border-radius: 16px;
    gap: 3px;
    margin-left: 3px;
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.ticket-count {
    border: none;
    padding: 0;
    width: 18px !important;
    text-align: center !important;
}

.ticket-quantity button {
    border: none;
    background: #fff;
    padding: 5px;
    height: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.direction {
    background-color: #F9FAFB;
    padding: 2px 6px;
    border: 1px solid #E4E7EC;
    border-radius: 8px;
    color: #344054;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-decoration: none;
}

.location-desc {
    color: #344054;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .5px;
}

.form-control:focus {
    box-shadow: none !important;
}