.qr-reader {
    /* background-image: url(../../../Assets/dupageDMMobile.png); */
    background-color: #706e6e;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    border-radius: 10px;
}

.video-box {
    border-radius: 10px;


}

.qr-reader video {
    width: 370px;
    height: 370px;
    object-fit: none;
}

.qr-reader .qr-box {

    width: 100% !important;
    left: 0 !important;
}

.qr-reader .qr-frame {

    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.option-text {
    color: #000;

    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.012px;
    margin-top: 4px;
}

.buttonStyles {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer
}

.overlayScan {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    width: 100%;
    height: 40%;
    transition: .5s all;
    transition-delay: .5s;
    transition-timing-function: ease-in-out;
    border-radius: 20px 20px 0 0;
    z-index: 3000
}

.matter {
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.free-text {
    color: #1EA133;
    font-size: 14px;
    font-weight: 500;
}

.title-desc {
    font-size: 14px;
    font-weight: 500;
}

.successScan {
    color: #1EA133;
    margin-bottom: 0;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.successTicketType {
    margin-bottom: 0;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.failedScan {
    color: #FF4C32;
    margin-bottom: 0;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.result-header {
    border-bottom: 1px solid #b3afaf
}

.attendee {
    color: #121212;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.attendee-ticket {
    color: #121212;
    margin-bottom: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ticket-id {
    color: #121212;

    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.resultButtons {
    color: #121212;

    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ticket-scan-head {
    color: #024DDF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.scan-total {
    color: #024DDF;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    /* 83.333% */
}

.data-back {
    background-color: #fff;
    border-radius: 10px;
    width: 330px;
    height: 200px;
}

.entry-close-text {
    color: #fff;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

/* Media Queries for mobile screens */
@media (max-width: 430px) {
    .qr-reader {
        width: 100%;
    }

}

@media (max-width: 768px) {
    .qr-reader video {
        width: 340px;
        height: 340px;
        object-fit: none;
    }

}