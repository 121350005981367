.otp-inputs div input {
  width: 56px !important;
  height: 56px !important;
  border-radius: 10px;
  border: 1px solid #7d7d7d;
  outline: none;
  margin: 0px 14px;
  color: #024ddf;
  font-size: 17px;
  font-weight: 500;
}

.otp-inputs div span {
  display: none;
}

/* .otp-inputs span {
  color: #7d7d7d;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
} */
.forgot-label {
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}

.forgot-modal h2 {
  color: #024ddf;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
}

.forgot-modal p {
  color: #121212;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.forgot-modal img {
  height: 320px;
  width: 320px;
}

.check-mail-msg {
  color: #E45D09;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}