.button-up {
    position: fixed;
    width: 50px;
    right: 50px;
    bottom: 40px;
    height: 50px;
    font-size: 3rem;
    cursor: pointer;
    z-index: 200;
}

.bi-arrow-up-circle {
    color: #344054;
}

.button-back {
    position: fixed;
    width: 50px;
    right: 100px;
    bottom: 40px;
    height: 50px;
    font-size: 3rem;
    cursor: pointer;
    z-index: 200;
}

@media (max-width: 768px) {
    .button-up {
        width: 20px;
        height: 20px;
    }

    .button-back {
        width: 20px;
        height: 20px;
    }

    .mb-sm-5rem {
        margin-bottom: 4.5rem !important;
    }
}