* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f9f4f0;
  font-size: 16px;
}

.new-bg {
  background-color: #f9f4f0;
}

/* ::selection {
  background-color: #f9f4f0;
} */

a {
  text-decoration: none;
}

.loader-box {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.loader-box span {
  position: absolute;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #fbaf80;
  -webkit-animation: loader-box 1.5s linear infinite;
  animation: loader-box 1.5s linear infinite;
}

.loader-box span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

.form-control:focus {
  box-shadow: none !important;
}

.no-result {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
}

@keyframes loader-box {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader-box {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

.ndf {
  min-height: 100vh;
  background-color: #f9f4f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-btn {
  padding: 10px 110px;
  border: 0;
  border-radius: 10px;
  background: black;
  color: white;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 10px 10px -10px blue;
}

.containers {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.bar {
  background-color: #024ddf;
  min-width: 100%;
  min-height: 5px;
  position: relative;
  overflow: hidden;
}

.bar::after {
  content: "";
  min-height: 5px;
  position: absolute;
  background: #fff;
  transform: translateX(-100%);
  animation: animate 2s infinite;
}

.bar::before {
  content: "";
  min-height: 5px;
  position: absolute;
  background: #fff;
  transform: translateX(-100%);
  animation: animate 2s infinite;
  animation-delay: 1s;
}

/* ::selection {
  background-color: #EF7171;
  color: #F49F9F;
} */

@keyframes animate {
  0% {
    transform: translateX(-150%);
    min-width: 100px;
  }

  100% {
    transform: translateX(300%);
    min-width: 400px;
  }
}

@media (max-width: 480px) {

  .Toastify__toast-container {
    width: 80vw !important;
    margin: auto !important;
    padding: 5px !important;
  }
}