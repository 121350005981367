.login-box {
    background: linear-gradient(to right, #024ddf 50%, #ffffff 50%);
    height: 100vh;
}

.root-container {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 20vh;

}

.head-box h3 {
    color: #fff;
    font-size: 54px;
    font-weight: 700;
    line-height: normal;
    margin-top: 50px;
}

.head-box img {
    width: 500px;
    height: 412px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.head-box {
    height: 100vh;
    position: relative;
}

.org-login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    width: 50%;

}

.signup-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
}

.signup-page img {
    width: 300px;
    height: 49.398px;
    margin-bottom: 12px;
}

.signup-page h6 {
    color: #121212;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.signup-page p {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
}

.signup-label {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}

.signup-input {
    padding: 14px 14px;
    border-radius: 10px;
    border: 0.8px solid rgba(0, 0, 0, 0.73);
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    width: 100%;
    /* margin-bottom: 12px; */
}

.signup-input-password {
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    border: hidden;
    width: 95%;
    /* margin-bottom: 12px; */
}

.signup-input-name {
    padding: 14px 14px;
    border-radius: 10px;
    border: 0.8px solid rgba(0, 0, 0, 0.73);
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    width: 100%;
    /* margin-bottom: 12px; */
}

.signup-page h5 {
    color: #121212;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-top: 14px;
}

.signup-page h5 span {
    color: #024ddf;
    text-decoration-line: underline;
}

.org-login-page img {
    width: 300px;
    height: 49.398px;
    margin-bottom: 30px;
}

.org-login-page h6 {
    color: #121212;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.org-login-page p {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-top: 40px;
}

.left-line {
    width: 220px;
    height: 1px;
    background: #b4b4b4;
}

.line {
    width: 400px;
    height: 1px;
    background: #b4b4b4;
}

.right-line {
    width: 220px;
    height: 1px;
    background: #b4b4b4;
}

.or {
    color: #121212 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin-bottom: 0px !important;
}

.form-box {
    padding: 10px 40px;
}

.label {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 14px;
}

.form-input {
    padding: 14px 14px;
    border-radius: 10px;
    border: 0.8px solid rgba(0, 0, 0, 0.73);
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    width: 100%;
    /* margin-bottom: 20px; */
}

.form-input1 {
    padding: 14px 14px;
    border-radius: 10px;
    border: 0.8px solid rgba(0, 0, 0, 0.73);
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    width: 95%;
    margin-bottom: 20px;
}

.forget-text {
    color: #024ddf !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.login-btn-disable {
    padding: 10px 110px;
    gap: 5px;
    border-radius: 10px;
    background: #e0e0e0;
    border: none;
    outline: none;
    pointer-events: none;
    cursor: not-allowed;
}

.login-btn {
    padding: 10px 110px;
    gap: 5px;
    border-radius: 10px;
    background: black;
    color: white;
    border: none;
    outline: none;
}

.org-login-page h5 {
    color: #121212;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}

.org-login-page h5 span {
    color: #024ddf;
    text-decoration-line: underline;
}

.show-hide:hover {
    z-index: 100;
}

.show-hide:hover {
    cursor: pointer;
}

.weak {
    color: #FF5252;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.medium {
    color: #FFD633;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.strong {
    color: #52FF53;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.very-strong {
    color: #06B507;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.door-management-btn {
    padding: 10px 110px;
    gap: 5px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: #024ddf;
    color: #fff;
}

@media (max-width: 768px) {
    .login-box {
        background: #024ddf;
        height: 100vh;
    }

    .head-box h3 {
        font-size: 35px;
        margin-top: 40px;
    }

    .head-box img {
        height: 360px;
    }

    .login-box {
        height: 85vh;
    }

    .head-box {
        height: 85vh;
    }

    .org-login-page {
        padding: 0;
        width: 100%;
    }

    .org-login-page img {
        width: 280px;
        height: 45.398px;
        margin-bottom: 20px;
    }

    .org-login-page p {
        margin-top: 20px;
    }

    .line {
        width: 200px;

    }

    .door-management-btn {
        padding: 10px 90px;

    }
}