.main-table {
    border: 1px solid #E4E7EC;
    border-radius: 12px;
    background: #FFFFFF;
}

.table-heading tr th {
    background-color: #f9fafb;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #475467;
    padding: 12px 24px;
}

.order-invoice {
    font-size: 14px !important;
    line-height: 20px !important;
    color: #101828 !important;
    font-weight: 500 !important;
}

.order-extra-details {
    font-size: 14px !important;
    line-height: 20px !important;
    color: #475467 !important;
    font-weight: 400 !important;
}

.table tr {
    padding: 16px 24px;
    border-radius: 12px;
    background-color: #ffffff;
    border: 1px solid #e4e7ec;
    margin-bottom: 12px;
}

.table {
    border: 1px solid #e4e7ec;
    border-radius: 12px;
    overflow: hidden;
    background-color: #ffffff;
}

.table thead {
    border-radius: 12px;
    background-color: #f9fafb;
}

.table tbody tr:last-child td {
    border-radius: 12px;
}

.table tbody tr:hover {
    background-color: #f5f7fa;
    transition: background-color 0.3s ease;
}

.table tbody tr td,
.table tr th {
    padding: 16px 24px;
}

.default-tag {
    padding: 2px 8px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    border-radius: 9999px;
}

.tag-paid {
    border: 1px solid #ABEFC6;
    background-color: #ECFDF3;
    color: #067647 !important;
}

.tag-refund {
    border: 1px solid #E4E7EC;
    background-color: #F9FAFB;
    color: #344054;
}

.tag-cancel {
    border: 1px solid #FECDCA;
    background-color: #FEF3F2;
    color: #BC1B06;
}

.delete-btn {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #475467;
    background: transparent;
    border: 0;
}

.ticket-more-btn {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #E45D09;
    text-decoration: none;
}

@media (max-width: 768px) {
    .table-heading tr th {
        font-size: 10px;
        padding: 8px 16px;
    }

    .order-invoice,
    .order-extra-details {
        font-size: 12px !important;
    }

    .table tbody tr td {
        padding: 12px 16px;
    }
}

.table tbody tr:hover {
    background-color: #f5f7fa;
    transition: background-color 0.3s ease;
}

.slick-slide {
    height: auto !important;
    margin: 0px 8px 0px 8px;
}

.order-details {
    color: #101828;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
}

.confirmed-h5 {
    color: #101828 !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
}

.ticket-desc-body {
    background: #FFFFFF;
    box-shadow: 0px 8px 30px -2px #1018280D;
    border-radius: 12px;
    padding: 16px;
}

.bookingId {
    color: #121212;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
}

.order-no {
    color: #121212;
    font-size: 14px;
    font-weight: 16px;
    font-weight: 500;
}

.qrcode-box {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.slick-arrow .slick-next {
    background-color: transparent !important;
}

.contact-history {
    color: #475467;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

.close-modal {
    color: #344054;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    padding: 10px 24px;
    border-radius: 16px;
    border: 1px solid #D0D5DD;
    background-color: #FFFFFF;
}